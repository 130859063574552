import { Alert, AlertTitle, Box, Breadcrumbs, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, Link, ListItemText, MenuItem, OutlinedInput, Paper, Select, Switch, TextareaAutosize, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router';
import { _get, _put, _validate } from '../../service/ApiClient';
import { Dropzone, FileMosaic } from '@files-ui/react';

const MyGayBox = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: 5,
    [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(20),
        marginLeft: theme.spacing(20),
        width: 'auto',
    },
    [theme.breakpoints.only('md')]: {
        marginRight: theme.spacing(14),
        marginLeft: theme.spacing(14),
        width: 'auto',
    },
    [theme.breakpoints.only('sm')]: {
        marginRight: theme.spacing(8),
        marginLeft: theme.spacing(8),
        width: 'auto',
    },
}));

const fetchData = async (setGame, gameId, errors, setErrors) => {
    try {
        const response = await _get(`/games/${gameId}`);
        setGame(response.data);
    } catch (error) {
        const field = 'gay'
        const message = error.response.data.message ? error.response.data.message : error.response.data.error;
        const myError = {
            field: field,
            message: message
        }
        setErrors({ ...errors, myError })
    }
};

const doUpdateGame = async (game, arm32, arm64, x32, x64, errors, setErrors, navTo) => {
    try {
        const formData = new FormData();
        formData.append('game', new Blob([JSON.stringify(game)], { type: "application/json" }));
        formData.append('file_arm_32', arm32?.file);
        formData.append('file_arm_64', arm64?.file);
        formData.append('file_x32', x32?.file);
        formData.append('file_x64', x64?.file);
        const headers = {
            "Content-type": "multipart/form-data",
        }
        const response = await _put(`/games/${game.id}/update`, formData, headers);
        if (response) {
            navTo(0);
        }
    } catch (error) {
        const field = 'gay';
        const message = error.response.data.message;
        const myError = {
            field: field,
            message: message
        }
        setErrors({ ...errors, myError })
    }
};

const fetchValidate = async (validationErrors, setValidationErrors, navigateTo) => {
    try {
        await _validate('/users/validate');
    } catch (error) {
        const field = 'gay'
        const message = error.response.data.message;
        const myError = {
            field: field,
            message: message
        }
        setValidationErrors({ ...validationErrors, myError })
        localStorage.clear();
        navigateTo('/login')
        navigateTo(0);
    }
};

const POSSIBLE_GAME_ARCH = [
    'ARM_V7A',
    'ARM_V8A',
];
const POSSIBLE_GAME_LANGUAGE = [
    'ENGLISH',
    'JAPANESE',
    'KOREAN',
    'CHINESE',
    'VIETNAMESE',
    'THAI',
    'OTHER'
];
const POSSIBLE_GAME_CATEGORY = [
    'ACTION',
    'ADVENTURE',
    'ARCADE',
    'BOARD',
    'CARD',
    'CASINO',
    'CASUAL',
    'EDUCATIONAL',
    'MUSIC',
    'PUZZLE',
    'RACING',
    'ROLE_PLAYING',
    'SIMULATION',
    'SPORTS',
    'STRATEGY',
    'TRIVIA',
    'WORD',
];
const BOOL_VARS = [
    'enabled',
    'ageRestricted',
    'featured'
]

function GamePage() {
    const navigateTo = useNavigate();
    const { gameId } = useParams();
    const [validateResponse, setValidateResponse] = useState();
    const [errors, setErrors] = useState();
    const [validationErrors, setValidationErrors] = useState();
    const [game, setGame] = useState(null);
    const [gameIcon, setGameIcon] = useState(null);
    const [gameArchs, setGameArchs] = useState([]);
    const [gameLanguages, setGameLanguages] = useState([]);
    const [gameCategories, setGameCategories] = useState([]);
    const [gameFeatures, setGameFeatures] = useState('');
    const [gameDownload, setGameDownload] = useState();
    const [isRootGame, setIsRootGame] = useState(false);
    const [arm32, setArm32] = useState([]);
    const [arm64, setArm64] = useState([]);
    const [x32, setX32] = useState([]);
    const [x64, setX64] = useState([]);

    const updateArm32 = (incommingFiles) => {
        setArm32(incommingFiles);
    };
    const removeArm32 = (id) => {
        setArm32(arm32.filter((x) => x.id !== id));
    };

    const updateArm64 = (incommingFiles) => {
        setArm64(incommingFiles);
    };
    const removeArm64 = (id) => {
        setArm64(arm64.filter((x) => x.id !== id));
    };

    const updateX32 = (incommingFiles) => {
        setX32(incommingFiles);
    };
    const removeX32 = (id) => {
        setX32(x32.filter((x) => x.id !== id));
    };

    const updateX64 = (incommingFiles) => {
        setX64(incommingFiles);
    };
    const removeX64 = (id) => {
        setX64(x64.filter((x) => x.id !== id));
    };

    const handleChangeList = (event, propKey) => {
        const { target: { value }, } = event;
        switch (propKey) {
            case 'categories':
                setGameCategories(value);
                break;
            case 'languages':
                setGameLanguages(value);
                break;
            case 'arches':
                setGameArchs(value);
                break;
            default:
                console.log('Ayo gay!')
                break;
        }
    };

    const handleChange = (event, propKey) => {
        const { target: { value, checked }, } = event;
        if (BOOL_VARS.indexOf(propKey) > -1) {
            game[propKey] = checked;
        } else {
            switch (propKey) {
                case 'iconUrl':
                    setGameIcon(value);
                    break;
                case 'dlName':
                    setGameDownload({ ...gameDownload, fileName: value })
                    break;
                case 'dlUrl':
                    setGameDownload({ ...gameDownload, fileUrl: value })
                    break;
                default:
                    game[propKey] = value;
            }
        }
        setGame(game);
    };

    const doPrintMyShit = () => {
        game.iconUrl = gameIcon;
        game.arches = gameArchs;
        game.languages = gameLanguages;
        game.categories = gameCategories;
        game.features = gameFeatures.split(',');
        if (gameDownload) {
            game.downloads[0] = gameDownload;
        }
        doUpdateGame(game, arm32[0], arm64[0], x32[0], x64[0], errors, setErrors, navigateTo);
    }

    useEffect(() => {
        setValidateResponse(fetchValidate(validationErrors, setValidationErrors, navigateTo));
    }, [validationErrors, setValidationErrors, navigateTo]);

    useEffect(() => {
        if (!errors && !validationErrors) {
            fetchData(setGame, gameId, errors, setErrors);
        }
    }, [errors, validationErrors, gameId, setGame])

    useEffect(() => {
        if (game != null) {
            setGameIcon(game.iconUrl)
            setGameArchs(game.arches);
            setGameLanguages(game.languages);
            setGameCategories(game.categories);
            setGameDownload(game.downloads[0]);
            setIsRootGame(game.downloads[0]?.extras?.length > 0);
            setGameFeatures(game.features.join(','));
        }
    }, [game]);

    const parseIconUrl = (iconUrl) => {
        if (iconUrl != null) {
            if (iconUrl.includes('o.qoo-img.com') || iconUrl.includes('play-lh')) {
                return `${iconUrl}150`;
            } else {
                return iconUrl;
            }
        }
        return 'https://placehold.co/150';
    }

    return (
        <>
            <MyGayBox>
                {errors &&
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, bgcolor: 'background.paper', borderRadius: 1, }}>
                        <Alert severity='error'>
                            <AlertTitle>Something went wrong</AlertTitle>
                            {errors.message}
                        </Alert>
                    </Box>
                }
                {!validateResponse && !game && !errors &&
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, bgcolor: 'background.paper', borderRadius: 1, }}>
                        <CircularProgress color='secondary' />
                    </Box>
                }
                {game &&
                    <>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <Link underline='hover' color='text.primary' onClick={() => navigateTo('/games')}> Games List </Link>
                            <Typography color='text.primary'>{game.title}</Typography>
                        </Breadcrumbs>
                        <Paper variant='outlined' sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <img key={new Date().getTime()} height={150} width={150} alt='game icon' src={parseIconUrl(game.iconUrl)} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={5}>
                                    <TextField
                                        color='secondary'
                                        required label='Title' id='title'
                                        defaultValue={game.title || ''}
                                        name='title'
                                        fullWidth
                                        onChange={(event) => handleChange(event, 'title')}
                                        error={Boolean(errors?.myError?.field === 'title')}
                                        helperText={Boolean(errors?.myError?.field === 'title') ? errors?.myError?.message : ''}
                                        variant='standard' />
                                </Grid>
                                <Grid item xs={12} sm={6} md={5}>
                                    <TextField
                                        color='secondary'
                                        required
                                        label='Package Name'
                                        id='packageName'
                                        defaultValue={game.packageName || ''}
                                        name='packageName'
                                        fullWidth
                                        onChange={(event) => handleChange(event, 'packageName')}
                                        error={Boolean(errors?.myError?.field === 'packageName')}
                                        helperText={Boolean(errors?.myError?.field === 'packageName') ? errors?.myError?.message : ''}
                                        variant='standard' />
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <TextField
                                        color='secondary'
                                        required
                                        label='Version'
                                        id='version'
                                        defaultValue={game.version || ''}
                                        name='version'
                                        fullWidth
                                        onChange={(event) => handleChange(event, 'version')}
                                        error={Boolean(errors?.myError?.field === 'version')}
                                        helperText={Boolean(errors?.myError?.field === 'version') ? errors?.myError?.message : ''}
                                        variant='standard' />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField color='secondary'
                                        required label='Icon Url'
                                        id='iconUrl'
                                        defaultValue={game.iconUrl || ''}
                                        name='iconUrl'
                                        fullWidth
                                        onChange={(event) => handleChange(event, 'iconUrl')}
                                        error={Boolean(errors?.myError?.field === 'iconUrl')}
                                        helperText={Boolean(errors?.myError?.field === 'iconUrl') ? errors?.myError?.message : ''}
                                        variant='standard' />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        color='secondary'
                                        label='Post Url'
                                        id='postUrl'
                                        defaultValue={game.postUrl || ''}
                                        name='postUrl'
                                        fullWidth
                                        onChange={(event) => handleChange(event, 'postUrl')}
                                        variant='standard' />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <FormControl color='secondary' required sx={{ m: 1, width: 350 }}>
                                        <InputLabel id='arch-input-label'>Select target architecture</InputLabel>
                                        <Select
                                            labelId='arch-input-multiple-checkbox-label'
                                            id='arch-input-multiple-checkbox'
                                            multiple
                                            value={gameArchs}
                                            onChange={(event) => handleChangeList(event, 'arches')}
                                            input={<OutlinedInput label='Select target architectures' />}
                                            error={Boolean(errors?.myError?.field === 'arches')}
                                            renderValue={(selected) => selected.join(', ')}
                                        >
                                            {POSSIBLE_GAME_ARCH.map((arch) => (
                                                <MenuItem key={arch} value={arch}>
                                                    <Checkbox checked={gameArchs?.indexOf(arch) > -1} />
                                                    <ListItemText primary={arch} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <FormControl color='secondary' required sx={{ m: 1, width: 350 }}>
                                        <InputLabel id='language-input-label'>Select target languages</InputLabel>
                                        <Select
                                            labelId='language-input-multiple-checkbox-label'
                                            id='language-input-multiple-checkbox'
                                            multiple
                                            value={gameLanguages}
                                            onChange={(event) => handleChangeList(event, 'languages')}
                                            input={<OutlinedInput label='Select target languages' />}
                                            error={Boolean(errors?.myError?.field === 'languages')}
                                            renderValue={(selected) => selected.join(', ')}
                                        >
                                            {POSSIBLE_GAME_LANGUAGE.map((lang) => (
                                                <MenuItem key={lang} value={lang}>
                                                    <Checkbox checked={gameLanguages?.indexOf(lang) > -1} />
                                                    <ListItemText primary={lang} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <FormControl color='secondary' required sx={{ m: 1, width: 350 }}>
                                        <InputLabel id='category-input-label'>Select target categories</InputLabel>
                                        <Select
                                            labelId='category-input-multiple-checkbox-label'
                                            id='category-input-multiple-checkbox'
                                            multiple
                                            value={gameCategories}
                                            onChange={(event) => handleChangeList(event, 'categories')}
                                            input={<OutlinedInput label='Select target categories' />}
                                            error={Boolean(errors?.myError?.field === 'categories')}
                                            renderValue={(selected) => selected.join(', ')}
                                        >
                                            {POSSIBLE_GAME_CATEGORY.map((cat) => (
                                                <MenuItem key={cat} value={cat}>
                                                    <Checkbox checked={gameCategories?.indexOf(cat) > -1} />
                                                    <ListItemText primary={cat} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <FormGroup>
                                        <FormControlLabel
                                            value='isEnabled'
                                            control={<Checkbox
                                                defaultChecked={game.enabled}
                                                color='secondary' />}
                                            label='Enabled'
                                            onChange={(event) => handleChange(event, 'enabled')}
                                            labelPlacement='top' />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <FormGroup>
                                        <FormControlLabel
                                            value='isR18'
                                            control={<Checkbox
                                                defaultChecked={game.ageRestricted}
                                                color='secondary' />}
                                            label='R18'
                                            onChange={(event) => handleChange(event, 'ageRestricted')}
                                            labelPlacement='top' />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <FormGroup>
                                        <FormControlLabel
                                            value='isFeatured'
                                            control={<Checkbox
                                                defaultChecked={game.featured}
                                                color='secondary' />}
                                            label='Featured'
                                            onChange={(event) => handleChange(event, 'featured')}
                                            labelPlacement='top' />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography key='features-label' variant='h6'>Feature(s):</Typography>
                                    <Typography key='features-label-desc' variant='p'>Values must be comma (,) separated</Typography>
                                    {gameFeatures &&
                                        <TextareaAutosize
                                            minRows={5}
                                            style={{ width: '100%', backgroundColor: '#d6d6d6' }}
                                            onChange={(event) => setGameFeatures(event.target.value)}
                                            defaultValue={gameFeatures} />
                                    }
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography key='files-label' variant='h6'>File(s):</Typography>
                                    <FormControl component='fieldset'>
                                        <FormControlLabel
                                            control={<Switch color='secondary' />}
                                            checked={isRootGame}
                                            onChange={() => setIsRootGame(!isRootGame)}
                                            label='Root'
                                            labelPlacement='start'
                                        />
                                    </FormControl>
                                </Grid>
                                {
                                    isRootGame === true &&
                                    <>
                                        {
                                            game?.uploadedExtras?.length > 0 &&
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Alert severity="warning">File(s) already present for the following ABIs: {game.uploadedExtras.join(',')}</Alert>
                                            </Grid>
                                        }
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Typography key='files-label-arm-32' variant='p'>ARM 32:</Typography>
                                            <Dropzone
                                                onChange={updateArm32}
                                                value={arm32}
                                                accept=".so"
                                                maxFileSize={2048 * 1024}
                                                maxFiles={1}
                                            >
                                                {arm32.map((file) => (
                                                    <FileMosaic key={file.id} {...file} onDelete={removeArm32} info />
                                                ))}
                                            </Dropzone>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Typography key='files-label-arm-64' variant='p'>ARM 64:</Typography>
                                            <Dropzone
                                                onChange={updateArm64}
                                                value={arm64}
                                                accept=".so"
                                                maxFileSize={2048 * 1024}
                                                maxFiles={1}
                                            >
                                                {arm64.map((file) => (
                                                    <FileMosaic key={file.id} {...file} onDelete={removeArm64} info />
                                                ))}
                                            </Dropzone>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Typography key='files-label-x-32' variant='p'>x32:</Typography>
                                            <Dropzone
                                                onChange={updateX32}
                                                value={x32}
                                                accept=".so"
                                                maxFileSize={2048 * 1024}
                                                maxFiles={1}
                                            >
                                                {x32.map((file) => (
                                                    <FileMosaic key={file.id} {...file} onDelete={removeX32} info />
                                                ))}
                                            </Dropzone>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Typography key='files-label-x-64' variant='p'>x64:</Typography>
                                            <Dropzone
                                                onChange={updateX64}
                                                value={x64}
                                                accept=".so"
                                                maxFileSize={2048 * 1024}
                                                maxFiles={1}
                                            >
                                                {x64.map((file) => (
                                                    <FileMosaic key={file.id} {...file} onDelete={removeX64} info />
                                                ))}
                                            </Dropzone>
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        color='secondary'
                                        label='File Name'
                                        required
                                        id='mainDownloadFileName'
                                        value={gameDownload?.fileName || ''}
                                        name='mainDownloadFileName'
                                        fullWidth
                                        onChange={(event) => handleChange(event, 'dlName')}
                                        error={Boolean(errors?.myError?.field === 'dlName') || Boolean(errors?.myError?.field === 'downloads')}
                                        helperText={Boolean(errors?.myError?.field === 'dlName') ? errors?.myError?.message : ''}
                                        variant='standard' />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        color='secondary'
                                        label='Download Link'
                                        required
                                        id='mainDownloadLink'
                                        value={gameDownload?.fileUrl || ''}
                                        name='mainDownloadLink'
                                        fullWidth
                                        onChange={(event) => handleChange(event, 'dlUrl')}
                                        error={Boolean(errors?.myError?.field === 'dlUrl') || Boolean(errors?.myError?.field === 'downloads')}
                                        helperText={Boolean(errors?.myError?.field === 'dlUrl') ? errors?.myError?.message : ''}
                                        variant='standard' />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Button variant='outlined' color='secondary' onClick={() => doPrintMyShit()}>Update</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </>
                }
            </MyGayBox>
        </>
    );
};

export default GamePage;