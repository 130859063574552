import React, { useState, useEffect } from 'react';
import { _validate } from '../../service/ApiClient.jsx';
import { useNavigate } from 'react-router';
import { styled } from '@mui/material/styles';
import { Alert, AlertTitle, Box, Button, CircularProgress, Divider, Grid, Stack } from '@mui/material';
import { AddCircleOutline, AdminPanelSettings, Person2Outlined, ViewListOutlined } from '@mui/icons-material';

const MyGayBox = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: 5,
    [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(20),
        marginLeft: theme.spacing(20),
        width: 'auto',
    },
    [theme.breakpoints.only('md')]: {
        marginRight: theme.spacing(14),
        marginLeft: theme.spacing(14),
        width: 'auto',
    },
    [theme.breakpoints.only('sm')]: {
        marginRight: theme.spacing(8),
        marginLeft: theme.spacing(8),
        width: 'auto',
    },
}));

const fetchData = async (errors, setErrors, navigateTo) => {
    try {
        await _validate('/users/validate');
    } catch (error) {
        const field = 'gay'
        const message = error.response.data.message;
        const myError = {
            field: field,
            message: message
        }
        setErrors({ ...errors, myError })
        localStorage.clear();
        navigateTo("/login")
        navigateTo(0);
    }
};

function HomePage() {

    const navigateTo = useNavigate();
    const [errors, setErrors] = useState();
    const [validateResponse, setValidateResponse] = useState();

    useEffect(() => {
        setValidateResponse(fetchData(errors, setErrors, navigateTo));
    }, [errors, setErrors, navigateTo]);

    const Item = styled(Button)(({ theme }) => ({
        ...theme.typography.h5,
        padding: theme.spacing(1),
        width: 150,
        height: 150,
        alignContent: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    }));

    return (
        <MyGayBox>
            {errors &&
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, bgcolor: 'background.paper', borderRadius: 1, }}>
                    <Alert severity='error'>
                        <AlertTitle>Something went wrong</AlertTitle>
                        {errors.message}
                    </Alert>
                </Box>
            }
            {!validateResponse &&
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, bgcolor: 'background.paper', borderRadius: 1, }}>
                    <CircularProgress color='secondary' />
                </Box>
            }
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, bgcolor: 'background.paper', borderRadius: 1, }}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} divider={<Divider orientation="vertical" flexItem />} >
                    <Item color='purple_A7' variant='outlined' elevation={3} onClick={() => navigateTo("/game/create")}>
                        <Grid container justifyContent={'center'}>
                            <Grid item xs={12} sm={12} md={12}>
                                <AddCircleOutline fontSize='large' />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                Add Game
                            </Grid>
                        </Grid>
                    </Item>
                    <Item color='purple_A7' variant='outlined' elevation={3} onClick={() => navigateTo("/games")}>
                        <Grid container justifyContent={'center'}>
                            <Grid item xs={12} sm={12} md={12}>
                                <ViewListOutlined fontSize='large' />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                Games
                            </Grid>
                        </Grid>
                    </Item>
                    <Item color='purple_A7' variant='outlined' elevation={3} onClick={() => navigateTo("/profile")}>
                        <Grid container justifyContent={'center'}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Person2Outlined fontSize='large' />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                Profile
                            </Grid>
                        </Grid>
                    </Item>
                    <Item color='purple_A7' variant='outlined' elevation={3} onClick={() => navigateTo("/acp")}>
                        <Grid container justifyContent={'center'}>
                            <Grid item xs={12} sm={12} md={12}>
                                <AdminPanelSettings fontSize='large' />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                ACP
                            </Grid>
                        </Grid>
                    </Item>
                </Stack>
            </Box>
        </MyGayBox>
    );
};

export default HomePage;