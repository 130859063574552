import { LockOutlined } from '@mui/icons-material';
import { Container, CssBaseline, Box, Avatar, Typography, TextField, Button } from '@mui/material';
import React, { useState } from 'react';
import { _login } from '../../service/ApiClient';
import { useNavigate } from 'react-router';

function LoginPage() {

    const navigateTo = useNavigate();

    const [errors, setErrors] = useState({})

    const doLogin = async (email, password) => {
        try {
            const loginData = { email: email, password: password };
            const response = await _login('/login', loginData);
            if (response) {
                localStorage.setItem('user_token', response.data.accessToken);
                console.log(localStorage.getItem('user_token'));
                navigateTo('/');
                navigateTo(0);
            }
        } catch (error) {
            const field = 'login';
            const message = error.response.data.message;
            const myError = {
                field: field,
                message: message
            }
            setErrors({ ...errors, myError })
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        doLogin(data.get('email'), data.get('password'));
    };

    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlined />
                </Avatar>
                <Typography component='h1' variant='h5'>
                    Sign in
                </Typography>
                <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin='normal'
                        required
                        fullWidth
                        id='email'
                        label='Email Address'
                        name='email'
                        autoComplete='email'
                        error={Boolean(errors?.myError?.field === 'login')}
                        autoFocus
                    />
                    <TextField
                        margin='normal'
                        required
                        fullWidth
                        name='password'
                        label='Password'
                        type='password'
                        id='password'
                        error={Boolean(errors?.myError?.field === 'login')}
                        helperText={Boolean(errors?.myError?.field === 'login') ? errors?.myError?.message : ''}
                        autoComplete='current-password'
                    />
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        sx={{ mt: 3, mb: 2 }}
                        color='secondary'>
                        Sign In
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

export default LoginPage;