import React, { useEffect, useState } from 'react';
import { _validate } from '../../service/ApiClient.jsx';
import { useNavigate } from 'react-router';
import { Alert, AlertTitle, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const MyGayBox = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: 5,
    [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(20),
        marginLeft: theme.spacing(20),
        width: 'auto',
    },
    [theme.breakpoints.only('md')]: {
        marginRight: theme.spacing(14),
        marginLeft: theme.spacing(14),
        width: 'auto',
    },
    [theme.breakpoints.only('sm')]: {
        marginRight: theme.spacing(8),
        marginLeft: theme.spacing(8),
        width: 'auto',
    },
}));

const fetchData = async (errors, setErrors, navigateTo) => {
    try {
        await _validate('/users/validate');
    } catch (error) {
        const field = 'gay'
        const message = error.response.data.message;
        const myError = {
            field: field,
            message: message
        }
        setErrors({ ...errors, myError })
        localStorage.clear();
        navigateTo("/login")
        navigateTo(0);
    }
};

function ProfilePage() {
    const navigateTo = useNavigate();
    const [errors, setErrors] = useState();
    const [validateResponse, setValidateResponse] = useState();

    useEffect(() => {
        setValidateResponse(fetchData(errors, setErrors, navigateTo));
    }, [errors, setErrors, navigateTo]);
    return (
        <MyGayBox>
            {errors &&
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, bgcolor: 'background.paper', borderRadius: 1, }}>
                    <Alert severity='error'>
                        <AlertTitle>Something went wrong</AlertTitle>
                        {errors.message}
                    </Alert>
                </Box>
            }
            {!validateResponse &&
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, bgcolor: 'background.paper', borderRadius: 1, }}>
                    <CircularProgress color='secondary' />
                </Box>
            }
            Profile Page . . . coming soon!
        </MyGayBox>
    );
};

export default ProfilePage;