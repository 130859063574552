import React, { useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid } from '@mui/x-data-grid';
import { _get, _put } from '../../service/ApiClient';
import { CircularProgress, List, ListItem } from '@mui/material';
import { _validate } from '../../service/ApiClient.jsx';
import { useNavigate } from 'react-router';

const CustomBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(20),
        marginLeft: theme.spacing(20),
        width: 'auto',
    },
    [theme.breakpoints.only('md')]: {
        marginRight: theme.spacing(14),
        marginLeft: theme.spacing(14),
        width: 'auto',
    },
    [theme.breakpoints.only('sm')]: {
        marginRight: theme.spacing(8),
        marginLeft: theme.spacing(8),
        width: 'auto',
    },
}))

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: 5,
    backgroundColor: alpha(theme.palette.secondary.dark, 0.60),
    '&:hover': {
        backgroundColor: alpha(theme.palette.secondary.dark, 0.30),
    },
    [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(20),
        marginLeft: theme.spacing(20),
        width: 'auto',
    },
    [theme.breakpoints.only('md')]: {
        marginRight: theme.spacing(14),
        marginLeft: theme.spacing(14),
        width: 'auto',
    },
    [theme.breakpoints.only('sm')]: {
        marginRight: theme.spacing(8),
        marginLeft: theme.spacing(8),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
}));

const parseIconUrl = (iconUrl) => {
    if (iconUrl != null) {
        if (iconUrl.includes('o.qoo-img.com') || iconUrl.includes('play-lh')) {
            return `${iconUrl}250`;
        } else {
            return iconUrl;
        }
    }
    return 'https://placehold.co/250';
}

const columns = [
    {
        field: 'iconUrl',
        headerName: 'Icon',
        description: 'Game Icon',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 75,
        renderHeader: (params) => (<strong>Icon</strong>),
        renderCell: (params) => (
            <Avatar alt="Game Icon" src={parseIconUrl(params.value)} sx={{ width: 50, height: 50 }} />
        ),
    },
    {
        field: 'enabled',
        headerName: 'Enabled',
        description: 'Is game enabled',
        minWidth: 75,
        renderHeader: (params) => (<strong>Enabled</strong>),
        renderCell: (params) => params.value === true ? 'Yes' : 'No'
    },
    {
        field: 'title',
        headerName: 'Title',
        description: 'Game Title',
        minWidth: 350,
        renderHeader: (params) => (<strong>Title</strong>),
    },
    {
        field: 'version',
        headerName: 'Version',
        description: 'Game Version',
        disableColumnMenu: true,
        minWidth: 100,
        renderHeader: (params) => (<strong>Version</strong>),
    },
    {
        field: 'packageName',
        headerName: 'Package',
        description: 'Game Package',
        minWidth: 400,
        renderHeader: (params) => (<strong>Package</strong>),
    },
    {
        field: 'ageRestricted',
        headerName: 'R18',
        description: 'Pornish',
        minWidth: 75,
        renderCell: (params) => params.value === true ? 'Yes' : 'No',
        renderHeader: (params) => (<strong>R18</strong>),
    },
];

const fetchData = async (setGameList, errors, setErrors) => {
    try {
        const response = await _get('/games/all');
        setGameList(response.data);
    } catch (error) {
        const field = 'gay'
        const message = error.response.data.message;
        const myError = {
            field: field,
            message: message
        }
        setErrors({ ...errors, myError })
    }
};

const enableGames = async (errors, setErrors, selectedGames) => {
    try {
        await _put('/games/enable?gameIds=' + selectedGames.map(game => game.id));
    } catch (error) {
        const field = error.response.data.field
        const message = error.response.data.message;
        const myError = {
            field: field,
            message: message
        }
        setErrors({ ...errors, myError })
    }
};

const disableGames = async (errors, setErrors, selectedGames) => {
    try {
        await _put('/games/disable?gameIds=' + selectedGames.map(game => game.id));
    } catch (error) {
        const field = error.response.data.field
        const message = error.response.data.message;
        const myError = {
            field: field,
            message: message
        }
        setErrors({ ...errors, myError })
    }
};

const fetchValidate = async (validationErrors, setValidationErrors, navigateTo) => {
    try {
        await _validate('/users/validate');
    } catch (error) {
        const field = 'gay'
        const message = error.response.data.message;
        const myError = {
            field: field,
            message: message
        }
        setValidationErrors({ ...validationErrors, myError })
        localStorage.clear();
        navigateTo("/login")
        navigateTo(0);
    }
};

const filterData = (query, data) => {
    if (!query) {
        return data;
    } else {
        return data.filter((d) => d.title.toLowerCase().includes(query) || d.packageName.toLowerCase().includes(query));
    }
};

function GamesPage() {

    const [gameList, setGameList] = useState();
    const [selectedGames, setSelectedGames] = useState([]);
    const [errors, setErrors] = useState();
    const [validationErrors, setValidationErrors] = useState();
    const [openEnableSelected, setOpenEnableSelected] = React.useState(false);
    const [openDisableSelected, setOpenDisableSelected] = React.useState(false);
    const navigateTo = useNavigate();

    const handleClickOpenEnable = () => {
        setOpenEnableSelected(true);
    };

    const handleCloseEnable = () => {
        setOpenEnableSelected(false);
    };

    const handleClickOpenDisable = () => {
        setOpenDisableSelected(true);
    };

    const handleCloseDisable = () => {
        setOpenDisableSelected(false);
    };

    const handleEnableGames = () => {
        enableGames(errors, setErrors, selectedGames);
        handleCloseEnable();
        navigateTo(0);
    }

    const handleDisableGames = () => {
        disableGames(errors, setErrors, selectedGames);
        handleCloseDisable();
        navigateTo(0);
    }

    useEffect(() => {
        fetchData(setGameList, errors, setErrors);
    }, [setGameList, errors, setErrors]);

    useEffect(() => {
        fetchValidate(validationErrors, setValidationErrors, navigateTo);
    }, [validationErrors, setValidationErrors, navigateTo]);

    const onRowsSelectionHandler = (ids) => {
        const selectedRowsData = ids.map((id) => gameList.find((row) => row.id === id));
        setSelectedGames(selectedRowsData)
    };

    const onRowDoubleClickHandler = (row) => {
        navigateTo(`/game/${row.id}`)
    }

    const prepareRow = (row) => {
        return <ListItem key={row.id}>{row.title}</ListItem>
    }

    const [searchQuery, setSearchQuery] = useState("");
    const dataFiltered = filterData(searchQuery, gameList);

    return (
        <>
            <Search onInput={(e) => { setSearchQuery(e.target.value); }}>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    fullWidth
                    placeholder="Search game by name or package name…"
                    inputProps={{ 'aria-label': 'search' }}
                />
            </Search>
            {selectedGames && <Dialog
                keepMounted
                open={openEnableSelected}
                onClose={handleCloseEnable}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Confirm ENABLE Action"}</DialogTitle>
                <DialogContent>
                    <Box>
                        <DialogContentText id="alert-dialog-slide-description">
                            Are you sure you want to enable the selected games:
                        </DialogContentText>
                        <List>
                            {selectedGames?.map(game => prepareRow(game))}
                        </List>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleCloseEnable}>Cancel</Button>
                    <Button color="secondary" onClick={handleEnableGames}>Proceed</Button>
                </DialogActions>
            </Dialog>}
            {selectedGames && <Dialog
                open={openDisableSelected}
                keepMounted
                onClose={handleCloseDisable}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Confirm DISABLE Action"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to disable the selected games:
                    </DialogContentText>
                    <List>
                        {selectedGames?.map(game => prepareRow(game))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleCloseDisable}>Cancel</Button>
                    <Button color="secondary" onClick={handleDisableGames}>Proceed</Button>
                </DialogActions>
            </Dialog>}
            {errors && <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 2,
                bgcolor: 'background.paper',
                borderRadius: 1,
            }}>
                <Alert severity="error">
                    <AlertTitle>Something went wrong</AlertTitle>
                    {errors}
                </Alert>
            </Box>}
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 2,
                bgcolor: 'background.paper',
                borderRadius: 1,
            }}>
                <ButtonGroup variant="contained" disableElevation color='purple_A7'>
                    <Button disabled={selectedGames.length === 0} onClick={handleClickOpenEnable}>Enable Selected</Button>
                    <Button onClick={() => navigateTo('/game/create')}>Add Game</Button>
                    <Button disabled={selectedGames.length === 0} onClick={handleClickOpenDisable}>Disable Selected</Button>
                </ButtonGroup>
            </Box>
            {!dataFiltered && <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 2,
                bgcolor: 'background.paper',
                borderRadius: 1,
            }}>
                <CircularProgress color="secondary" />
            </Box>}
            {dataFiltered && <CustomBox
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 2,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                }}
            >
                <DataGrid
                    autoHeight
                    rows={dataFiltered}
                    columns={columns}
                    onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                    onRowDoubleClick={(row) => onRowDoubleClickHandler(row)}
                    sx={{
                        boxShadow: 2,
                        border: 2,
                        borderColor: 'secondary.dark',
                        '& .MuiDataGrid-cell:hover': {
                            color: 'secondary.dark',
                        },
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10]}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
            </CustomBox>}
        </>
    );
}

export default GamesPage;