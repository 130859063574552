import React from 'react';
import MuffinContainer from '../MuffinContainer/MuffinContainer.jsx'
import ResponsiveAppBar from '../ResponsiveAppBar/ResponsiveAppBar.jsx'
import FixedFooter from '../FixedFooter/FixedFooter.jsx'
import PageContainer from '../PageContainer/PageContainer.jsx'
import GamesPage from '../../pages/GamesPage/GamesPage.jsx'
import HomePage from '../../pages/HomePage/HomePage.jsx'
import LoginPage from '../../pages/LoginPage/LoginPage.jsx'
import GuestPage from '../../pages/GuestPage/GuestPage.jsx'
import RegistrationPage from '../../pages/RegistrationPage/RegistrationPage.jsx'

import './App.css';
import { Route, Routes } from 'react-router-dom';
import ProfilePage from '../../pages/ProfilePage/ProfilePage.jsx';
import { alpha, createTheme, useTheme, PaletteColorOptions, ThemeProvider, CssBaseline } from '@mui/material';
import GamePage from '../../pages/GamePage/GamePage.jsx';
import GameCreate from '../../pages/GameCreate/GameCreate.jsx';
import AdminPanel from '../../pages/AdminPanel/AdminPanel.jsx';

declare module '@mui/material/styles' {
  interface CustomPalette {
    purple_A7: PaletteColorOptions;
  }
  interface Palette extends CustomPalette { }
  interface PaletteOptions extends CustomPalette { }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    purple_A7: true;
  }
}

function App() {

  const accessToken = localStorage.getItem("user_token");

  const theme = useTheme()

  const { palette } = createTheme();
  const { augmentColor } = palette;
  const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
    
  const customeTheme = createTheme({
    palette: {
      purple_A7: createColor(alpha(theme.palette.secondary.dark, 0.70)),
      background: {
        default: '#d6d6d6',
        paper: '#d6d6d6'
      }
    }
  });

  return (
    <ThemeProvider theme={customeTheme}>
      <CssBaseline />
      <MuffinContainer>
        <ResponsiveAppBar token={accessToken} />
        <Routes>
          {accessToken && (
            <>
              <Route index path='/' element={<PageContainer><HomePage /></PageContainer>} />
              <Route path='/games' element={<PageContainer><GamesPage /></PageContainer>} />
              <Route path='/game/:gameId' element={<PageContainer><GamePage /></PageContainer>} />
              <Route path='/game/create' element={<PageContainer><GameCreate /></PageContainer>} />
              <Route path='/profile' element={<PageContainer><ProfilePage /></PageContainer>} />              
              <Route path='/acp' element={<PageContainer><AdminPanel /></PageContainer>} />
            </>
          )}
          {!accessToken && (
            <>
              <Route index path='/' element={<PageContainer><GuestPage /></PageContainer>} />
              <Route path='/login' element={<PageContainer><LoginPage /></PageContainer>} />
              <Route path='/register' element={<PageContainer><RegistrationPage /></PageContainer>} />
            </>
          )}
        </Routes>
        <FixedFooter />
      </MuffinContainer>
    </ThemeProvider>
  );
}

export default App;