import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL
const AUTH_URL = process.env.REACT_APP_AUTH_URL

const apiClient = axios.create({
  headers: {
    'Authorization': 'Bearer ' + localStorage.getItem("user_token")
    // You can add other headers like authorization token here
  },
});

const apiClientLogin = axios.create({
  headers: {
    'Content-Type': 'application/json',
    // You can add other headers like authorization token here
  },
});

// Define common API methods
const _get = (url, config = {}) => {
  return apiClient.get(BASE_URL + url, config);
};

const _delete = (url, config = {}) => {
  return apiClient.delete(BASE_URL + url, config);
};

const _put = (url, data = {}, config = {}) => {
  return apiClient.put(BASE_URL + url, data, config);
};

const _post = (url, data = {}, config = {}) => {
  return apiClient.post(BASE_URL + url, data, config);
};

const _login = (url, data = {}, config = {}) => {
  return apiClientLogin.post(AUTH_URL + url, data, config);
};

const _register = (url, data = {}, config = {}) => {
  return apiClientLogin.post(AUTH_URL + url, data, config);
};

const _validate = (url, data = {}, config = {}) => {
  return apiClient.post(BASE_URL + url, data, config);
};

// Export API methods
export { _get, _delete, _put, _post, _login, _register, _validate };