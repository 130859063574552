import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Alert, AlertTitle, Box, Breadcrumbs, Button, Checkbox, Fade, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, Switch, TextareaAutosize, TextField, Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Dropzone, FileMosaic } from '@files-ui/react';
import { _post } from '../../service/ApiClient';

const MyGayBox = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: 5,
    [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(20),
        marginLeft: theme.spacing(20),
        width: 'auto',
    },
    [theme.breakpoints.only('md')]: {
        marginRight: theme.spacing(14),
        marginLeft: theme.spacing(14),
        width: 'auto',
    },
    [theme.breakpoints.only('sm')]: {
        marginRight: theme.spacing(8),
        marginLeft: theme.spacing(8),
        width: 'auto',
    },
}));

const POSSIBLE_GAME_ARCH = [
    'ARM_V7A',
    'ARM_V8A',
];
const POSSIBLE_GAME_LANGUAGE = [
    'ENGLISH',
    'JAPANESE',
    'KOREAN',
    'CHINESE',
    'VIETNAMESE',
    'THAI',
    'OTHER'
];
const POSSIBLE_GAME_CATEGORY = [
    'ACTION',
    'ADVENTURE',
    'ARCADE',
    'BOARD',
    'CARD',
    'CASINO',
    'CASUAL',
    'EDUCATIONAL',
    'MUSIC',
    'PUZZLE',
    'RACING',
    'ROLE_PLAYING',
    'SIMULATION',
    'SPORTS',
    'STRATEGY',
    'TRIVIA',
    'WORD',
];

const doCreateGame = async (game, arm32, arm64, x32, x64, errors, setErrors, navTo) => {
    try {
        const formData = new FormData();
        formData.append('game', new Blob([JSON.stringify(game)], { type: "application/json" }));
        formData.append('file_arm_32', arm32?.file);
        formData.append('file_arm_64', arm64?.file);
        formData.append('file_x32', x32?.file);
        formData.append('file_x64', x64?.file);
        const headers = {
            "Content-type": "multipart/form-data",
        }
        console.log(formData)
        const response = await _post(`/games/create`, formData, headers);
        if (response) {
            console.log(response.data)
            navTo(`/game/${response.data.id}`);
        }
    } catch (error) {
        const field = error.response.data.field;
        const message = error.response.data.message;
        const myError = {
            field: field,
            message: message
        }
        setErrors({ ...errors, myError })
    }
};

function GameCreate() {

    const [errors, setErrors] = useState();
    const [gameDownload, setGameDownload] = useState({
        cheatType: 'MOD_APK',
        downloadType: 'EXTERNAL',
        fileName: null,
        fileUrl: null,
        extras: []
    });
    const [game, setGame] = useState({
        title: null,
        packageName: null,
        postUrl: null,
        version: null,
        iconUrl: null,
        featured: false,
        enabled: false,
        ageRestricted: false,
        arches: [],
        categories: [],
        languages: [],
        features: [],
        downloads: [gameDownload]
    });
    const [gameTitle, setGameTitle] = useState();
    const [gameVersion, setGameVersion] = useState();
    const [gamePackageName, setGamePackageName] = useState();
    const [gameIconUrl, setGameIconUrl] = useState();
    const [gamePostUrl, setGamePostUrl] = useState();
    const [gameArchs, setGameArchs] = useState([]);
    const [gameLanguages, setGameLanguages] = useState([]);
    const [gameCategories, setGameCategories] = useState([]);
    const [gameEnabled, setGameEnabled] = useState(false);
    const [gameFeatured, setGameFeatured] = useState(false);
    const [gameAgeRestricted, setGameAgeRestricted] = useState(false);
    const [isRootGame, setIsRootGame] = useState(false);
    const [gameFeatures, setGameFeatures] = useState('');
    const [gameDownloadName, setGameDownloadName] = useState();
    const [gameDownloadUrl, setGameDownloadUrl] = useState();
    const [arm32, setArm32] = useState([]);
    const [arm64, setArm64] = useState([]);
    const [x32, setX32] = useState([]);
    const [x64, setX64] = useState([]);

    const navigateTo = useNavigate();

    const handleChangeList = (event, propKey) => {
        const { target: { value }, } = event;
        switch (propKey) {
            case 'categories':
                setGameCategories(value);
                setGame({ ...game, categories: value })
                break;
            case 'languages':
                setGameLanguages(value);
                setGame({ ...game, languages: value })
                break;
            case 'arches':
                setGameArchs(value);
                setGame({ ...game, arches: value })
                break;
            default:
                console.log('Ayo gay!')
                break;
        }
    };

    const handleChange = (event, propKey) => {
        const { target: { value, checked }, } = event;
        switch (propKey) {
            case 'iconUrl':
                setGameIconUrl(value);
                setGame({ ...game, iconUrl: value });
                break;
            case 'title':
                setGameTitle(value);
                setGame({ ...game, title: value });
                break;
            case 'packageName':
                setGamePackageName(value);
                setGame({ ...game, packageName: value });
                break;
            case 'version':
                setGameVersion(value);
                setGame({ ...game, version: value });
                break;
            case 'postUrl':
                setGamePostUrl(value);
                setGame({ ...game, postUrl: value });
                break;
            case 'enabled':
                setGameEnabled(checked);
                setGame({ ...game, enabled: checked });
                break;
            case 'featured':
                setGameFeatured(checked);
                setGame({ ...game, featured: checked });
                break;
            case 'ageRestricted':
                setGameAgeRestricted(checked);
                setGame({ ...game, ageRestricted: checked });
                break;
            case 'dlName':
                setGameDownloadName(value);
                setGameDownload({ ...gameDownload, fileName: value });
                setGame({ ...game, downloads: [gameDownload] });
                break;
            case 'dlUrl':
                setGameDownloadUrl(value);
                setGameDownload({ ...gameDownload, fileUrl: value });
                setGame({ ...game, downloads: [gameDownload] });
                break;
            default:
                console.log('Are you actually gay !?');
                break;
        }
    };

    const updateArm32 = (incommingFiles) => {
        setArm32(incommingFiles);
    };
    const removeArm32 = (id) => {
        setArm32(arm32.filter((x) => x.id !== id));
    };

    const updateArm64 = (incommingFiles) => {
        setArm64(incommingFiles);
    };
    const removeArm64 = (id) => {
        setArm64(arm64.filter((x) => x.id !== id));
    };

    const updateX32 = (incommingFiles) => {
        setX32(incommingFiles);
    };
    const removeX32 = (id) => {
        setX32(x32.filter((x) => x.id !== id));
    };

    const updateX64 = (incommingFiles) => {
        setX64(incommingFiles);
    };
    const removeX64 = (id) => {
        setX64(x64.filter((x) => x.id !== id));
    };

    const doPrintMyShit = () => {
        doCreateGame(game, arm32[0], arm64[0], x32[0], x64[0], errors, setErrors, navigateTo);
    }

    const parseIconUrl = (iconUrl) => {
        if (iconUrl != null) {
            if (iconUrl.includes('o.qoo-img.com') || iconUrl.includes('play-lh')) {
                return `${iconUrl}150`;
            } else {
                return iconUrl;
            }
        }
        return 'https://placehold.co/150';
    }

    return (
        <MyGayBox>
            {errors &&
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, bgcolor: 'background.paper', borderRadius: 1, }}>
                    <Alert severity='error'>
                        <AlertTitle>Something went wrong</AlertTitle>
                        {errors.message}
                    </Alert>
                </Box>
            }
            <Breadcrumbs aria-label='breadcrumb'>
                <Link underline='hover' color='text.primary' onClick={() => navigateTo(-1)}> Games List </Link>
                <Typography color='text.primary'>Create Game</Typography>
            </Breadcrumbs>
            <Paper variant='outlined' sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Grid container component='form' spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <img key={new Date().getTime()} height={150} width={150} alt='game icon' src={parseIconUrl(gameIconUrl)} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={5}>
                        <TextField
                            color='secondary'
                            required label='Title' id='title'
                            defaultValue={gameTitle || ''}
                            name='title'
                            fullWidth
                            onChange={(event) => handleChange(event, 'title')}
                            error={Boolean(errors?.myError?.field === 'title')}
                            helperText={Boolean(errors?.myError?.field === 'title') ? errors?.myError?.message : ''}
                            variant='standard' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={5}>
                        <TextField
                            color='secondary'
                            required
                            label='Package Name'
                            id='packageName'
                            defaultValue={gamePackageName || ''}
                            name='packageName'
                            fullWidth
                            onChange={(event) => handleChange(event, 'packageName')}
                            error={Boolean(errors?.myError?.field === 'packageName')}
                            helperText={Boolean(errors?.myError?.field === 'packageName') ? errors?.myError?.message : ''}
                            variant='standard' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            color='secondary'
                            required
                            label='Version'
                            id='version'
                            defaultValue={gameVersion || ''}
                            name='version'
                            fullWidth
                            onChange={(event) => handleChange(event, 'version')}
                            error={Boolean(errors?.myError?.field === 'version')}
                            helperText={Boolean(errors?.myError?.field === 'version') ? errors?.myError?.message : ''}
                            variant='standard' />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}
                            title="Google Play or QooApp icon, please do not include last part (ex. 48-rw {Google Play} or 192 {QooApp}) as for other source please use Imgur (ex. https://i.imgur.com/GGCYtJY.png)."
                            placement="top" arrow>
                            <TextField color='secondary'
                                required label='Icon Url'
                                id='iconUrl'
                                defaultValue={gameIconUrl || ''}
                                name='iconUrl'
                                fullWidth
                                onChange={(event) => handleChange(event, 'iconUrl')}
                                error={Boolean(errors?.myError?.field === 'iconUrl')}
                                helperText={Boolean(errors?.myError?.field === 'iconUrl') ? errors?.myError?.message : ''}
                                variant='standard' />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            color='secondary'
                            label='Post Url'
                            id='postUrl'
                            defaultValue={gamePostUrl || ''}
                            name='postUrl'
                            fullWidth
                            onChange={(event) => handleChange(event, 'postUrl')}
                            variant='standard' />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControl color='secondary' required sx={{ m: 1, width: 350 }}>
                            <InputLabel id='arch-input-label'>Select target architecture</InputLabel>
                            <Select
                                labelId='arch-input-multiple-checkbox-label'
                                id='arch-input-multiple-checkbox'
                                multiple
                                value={gameArchs}
                                onChange={(event) => handleChangeList(event, 'arches')}
                                input={<OutlinedInput label='Select target architectures' />}
                                error={Boolean(errors?.myError?.field === 'arches')}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {POSSIBLE_GAME_ARCH.map((arch) => (
                                    <MenuItem key={arch} value={arch}>
                                        <Checkbox checked={gameArchs?.indexOf(arch) > -1} />
                                        <ListItemText primary={arch} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControl color='secondary' required sx={{ m: 1, width: 350 }}>
                            <InputLabel id='language-input-label'>Select target languages</InputLabel>
                            <Select
                                labelId='language-input-multiple-checkbox-label'
                                id='language-input-multiple-checkbox'
                                multiple
                                value={gameLanguages}
                                onChange={(event) => handleChangeList(event, 'languages')}
                                input={<OutlinedInput label='Select target languages' />}
                                error={Boolean(errors?.myError?.field === 'languages')}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {POSSIBLE_GAME_LANGUAGE.map((lang) => (
                                    <MenuItem key={lang} value={lang}>
                                        <Checkbox checked={gameLanguages?.indexOf(lang) > -1} />
                                        <ListItemText primary={lang} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControl color='secondary' required sx={{ m: 1, width: 350 }}>
                            <InputLabel id='category-input-label'>Select target categories</InputLabel>
                            <Select
                                labelId='category-input-multiple-checkbox-label'
                                id='category-input-multiple-checkbox'
                                multiple
                                value={gameCategories}
                                onChange={(event) => handleChangeList(event, 'categories')}
                                input={<OutlinedInput label='Select target categories' />}
                                error={Boolean(errors?.myError?.field === 'categories')}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {POSSIBLE_GAME_CATEGORY.map((cat) => (
                                    <MenuItem key={cat} value={cat}>
                                        <Checkbox checked={gameCategories?.indexOf(cat) > -1} />
                                        <ListItemText primary={cat} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                        <FormGroup>
                            <FormControlLabel
                                value='isEnabled'
                                control={<Checkbox
                                    checked={gameEnabled}
                                    color='secondary' />}
                                label='Enabled'
                                onChange={(event) => handleChange(event, 'enabled')}
                                labelPlacement='top' />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                        <FormGroup>
                            <FormControlLabel
                                value='isR18'
                                control={<Checkbox
                                    checked={gameAgeRestricted}
                                    color='secondary' />}
                                label='R18'
                                onChange={(event) => handleChange(event, 'ageRestricted')}
                                labelPlacement='top' />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                        <FormGroup>
                            <FormControlLabel
                                value='isFeatured'
                                control={<Checkbox
                                    checked={gameFeatured}
                                    color='secondary' />}
                                label='Featured'
                                onChange={(event) => handleChange(event, 'featured')}
                                labelPlacement='top' />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography key='features-label' variant='h6'>Features:</Typography>
                        <Typography key='features-label-desc' variant='p'>Values must be comma (,) separated</Typography>
                        <TextareaAutosize
                            minRows={5}
                            style={{ width: '100%', backgroundColor: '#d6d6d6' }}
                            onChange={(event) => {
                                setGameFeatures(event.target.value);
                                setGame({ ...game, features: event.target.value.split(',') });
                            }}
                            defaultValue={gameFeatures} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography key='files-label' variant='h6'>Files:</Typography>
                        <FormControl component='fieldset'>
                            <FormControlLabel
                                control={<Switch color='secondary' />}
                                checked={isRootGame}
                                onChange={() => setIsRootGame(!isRootGame)}
                                label='Root'
                                labelPlacement='start'
                            />
                        </FormControl>
                    </Grid>
                    {
                        isRootGame === true &&
                        <>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography key='files-label-arm-32' variant='p'>ARM 32:</Typography>
                                <Dropzone
                                    onChange={(files) => updateArm32(files)}
                                    value={arm32}
                                    accept=".so"
                                    maxFileSize={2048 * 1024}
                                    maxFiles={1}
                                >
                                    {arm32.map((file) => (
                                        <FileMosaic key={file.id} {...file} onDelete={removeArm32} info />
                                    ))}
                                </Dropzone>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography key='files-label-arm-64' variant='p'>ARM 64:</Typography>
                                <Dropzone
                                    onChange={updateArm64}
                                    value={arm64}
                                    accept=".so"
                                    maxFileSize={2048 * 1024}
                                    maxFiles={1}
                                >
                                    {arm64.map((file) => (
                                        <FileMosaic key={file.id} {...file} onDelete={removeArm64} info />
                                    ))}
                                </Dropzone>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography key='files-label-x-32' variant='p'>x32:</Typography>
                                <Dropzone
                                    onChange={updateX32}
                                    value={x32}
                                    accept=".so"
                                    maxFileSize={2048 * 1024}
                                    maxFiles={1}
                                >
                                    {x32.map((file) => (
                                        <FileMosaic key={file.id} {...file} onDelete={removeX32} info />
                                    ))}
                                </Dropzone>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography key='files-label-x-64' variant='p'>x64:</Typography>
                                <Dropzone
                                    onChange={updateX64}
                                    value={x64}
                                    accept=".so"
                                    maxFileSize={2048 * 1024}
                                    maxFiles={1}
                                >
                                    {x64.map((file) => (
                                        <FileMosaic key={file.id} {...file} onDelete={removeX64} info />
                                    ))}
                                </Dropzone>
                            </Grid>
                        </>
                    }
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            color='secondary'
                            label='File Name'
                            required
                            id='mainDownloadFileName'
                            defaultValue={gameDownloadName || ''}
                            name='mainDownloadFileName'
                            fullWidth
                            onChange={(event) => handleChange(event, 'dlName')}
                            error={Boolean(errors?.myError?.field === 'dlName') || Boolean(errors?.myError?.field === 'downloads')}
                            helperText={Boolean(errors?.myError?.field === 'dlName') ? errors?.myError?.message : ''}
                            variant='standard' />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            color='secondary'
                            label='Download Link'
                            required
                            id='mainDownloadLink'
                            defaultValue={gameDownloadUrl || ''}
                            name='mainDownloadLink'
                            fullWidth
                            onChange={(event) => handleChange(event, 'dlUrl')}
                            error={Boolean(errors?.myError?.field === 'dlUrl') || Boolean(errors?.myError?.field === 'downloads')}
                            helperText={Boolean(errors?.myError?.field === 'dlUrl') ? errors?.myError?.message : ''}
                            variant='standard' />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Button variant='outlined' color='secondary' onClick={() => doPrintMyShit()}>Create Game</Button>
                    </Grid>
                </Grid>
            </Paper>
        </MyGayBox>
    );
};

export default GameCreate;