import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router';
import { _register } from '../../service/ApiClient';

function RegistrationPage() {

    const navigateTo = useNavigate();

    const [errors, setErrors] = useState({})

    const doRegister = async (username, email, password, challengeCode) => {
        try {
            const regData = { username: username, email: email, password: password, challengeCode: challengeCode };
            const response = await _register('/register', regData);
            if (response) {
                navigateTo('/login');
                navigateTo(0);
            }
        } catch (error) {
            const field = error.response.data.field;
            const message = error.response.data.message;
            const myError = {
                field: field,
                message: message
            }
            setErrors({ ...errors, myError })
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        doRegister(data.get('username'), data.get('email'), data.get('password'), data.get('challengeCode'));
    };

    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component='h1' variant='h5'>
                    Sign up
                </Typography>
                <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete='username'
                                name='username'
                                required
                                fullWidth
                                id='username'
                                label='Username'
                                error={Boolean(errors?.myError?.field === 'username')}
                                helperText={Boolean(errors?.myError?.field === 'username') ? errors?.myError?.message : ''}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id='email'
                                label='Email Address'
                                name='email'
                                type='email'
                                error={Boolean(errors?.myError?.field === 'email')}
                                helperText={Boolean(errors?.myError?.field === 'email') ? errors?.myError?.message : ''}
                                autoComplete='email'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name='password'
                                label='Password'
                                type='password'
                                id='password'
                                error={Boolean(errors?.myError?.field === 'password')}
                                helperText={Boolean(errors?.myError?.field === 'password') ? errors?.myError?.message : ''}
                                autoComplete='new-password'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name='challengeCode'
                                label='Input the code'
                                type='challengeCode'
                                id='challengeCode'
                                error={Boolean(errors?.myError?.field === 'challengeCode')}
                                helperText={Boolean(errors?.myError?.field === 'challengeCode') ? errors?.myError?.message : ''}
                                autoComplete='challengeCode'
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign Up
                    </Button>
                    <Grid container justifyContent='flex-end'>
                        <Grid item>
                            <Link href='/login' variant='body2'>
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};

export default RegistrationPage;