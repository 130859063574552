import { Paper, Typography, Link } from '@mui/material';
import React from 'react';
import { styled, alpha } from '@mui/material/styles';

function FixedFooter() {
    const StyledPaper = styled(Paper)(({ theme }) => ({
        position: 'fixed',
        bottom: 0,
        backgroundColor: alpha(theme.palette.secondary.dark, 0.70),
        width: '100%'
    }));
    return (
        <StyledPaper square variant="outlined" >
            <Typography
                variant="body2"
                color="textPrimary"
                align="center"
            >
                {"Copyright © "}
                <Link color="inherit" target="_blank" href="https://github.com/LaughingMuffin">
                    The Muffinator - Laughing Muffin
                </Link>{" - "}
                {new Date().getFullYear()}
                {""}
            </Typography>
        </StyledPaper>
    );
}

export default FixedFooter;