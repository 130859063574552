import React, { useState } from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography, Container, Avatar } from '@mui/material';
import { Icon } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import { styled, alpha } from '@mui/material/styles';
import { AppRegistrationOutlined, LoginOutlined, LogoutOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router';

function ResponsiveAppBar({ token }) {

  const navigateTo = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  const handleLogout = () => {
    localStorage.removeItem("user_token");
    navigateTo("/");
    navigateTo(0);
  }

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      <List>
        {token && (
          <>
            <ListItem disablePadding>
              <ListItemButton onClick={() => {
                navigateTo("/games")
                navigateTo(0);
              }}>
                <ListItemIcon>
                  <VideogameAssetIcon />
                </ListItemIcon>
                <ListItemText primary="Games List" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleLogout()}>
                <ListItemIcon>
                  <LogoutOutlined />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
          </>
        )}
        {!token && (
          <>
            <ListItem disablePadding>
              <ListItemButton onClick={() => {
                navigateTo("/login")
                navigateTo(0);
              }}>
                <ListItemIcon>
                  <LoginOutlined />
                </ListItemIcon>
                <ListItemText primary="Sign In" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => {
                navigateTo("/register")
                navigateTo(0);
              }}>
                <ListItemIcon>
                  <AppRegistrationOutlined />
                </ListItemIcon>
                <ListItemText primary="Sign Up" />
              </ListItemButton>
            </ListItem>
          </>
        )}

      </List>
    </Box>
  );

  const StyledAppBar = styled(AppBar)(({ theme }) => ({
    position: 'sticky',
    backgroundColor: alpha(theme.palette.secondary.dark, 0.70),
  }));

  return (
    <StyledAppBar>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Icon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}><img src="../../../fav_icon.png" alt='Logo' /></Icon>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            MUFFINATOR
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => toggleDrawer(true)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor={"left"}
              open={drawerOpen}
              onClose={() => toggleDrawer(false)}
            >
              {list("left")}
            </Drawer>
          </Box>
          <Icon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}><img src="../../../fav_icon.png" alt='Logo' /></Icon>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            MUFFINATOR
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {token && (
              <>
                <Button sx={{ my: 2, color: 'white', display: 'block' }} onClick={() => {
                  navigateTo("/games")
                  navigateTo(0);
                }}>
                  Game List
                </Button>
                <Button sx={{ my: 2, color: 'white', display: 'block' }} onClick={() => handleLogout()}>
                  Logout
                </Button>
              </>
            )}
            {!token && (
              <>
                <Button sx={{ my: 2, color: 'white', display: 'block' }} onClick={() => {
                  navigateTo("/login")
                  navigateTo(0);
                }} >
                  Login
                </Button>
                <Button sx={{ my: 2, color: 'white', display: 'block' }} onClick={() => {
                  navigateTo("/register")
                  navigateTo(0);
                }}>
                  Register
                </Button>
              </>
            )}
          </Box>

          {token && (
            <Box sx={{ flexGrow: 0 }}>
              <IconButton sx={{ p: 0 }} onClick={() => navigateTo("/profile")}>
                <Avatar alt="Muffin" srcSet='#empty' variant="rounded" />
              </IconButton>
            </Box>
          )}
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
}
export default ResponsiveAppBar;